<template>
    <div>
        <Header title="Condôminos" addText="Usuário" :hasAddBtn="rules.includes('create.auth.api') && $store.getters.isAdmin" :addFields="addFields" :meta="meta" :extraFields="null" extraMode="attachToGroup"/>

        <b-table sticky-header="65vh" no-border-collapse hover :items="users" :fields="fields" :busy="busy">
            <template #cell(actions)="row">
                <div class="btn-icons">
                    <div v-if="rules.includes('edit.auth.api')"><i @click="$emit('callModal', 'form-modal', 'edit', row.item, addFields, meta, null, '')" class = "material-icons btn-icon">edit</i><span style="white-space: nowrap">Editar</span></div>
                    <div v-if="rules.includes('index.object.api')"><i @click="boletos(row.item)" class = "material-icons btn-icon">request_quote</i><span style="white-space: nowrap">Ver Boletos</span></div>
                    <div><i @click="$emit('callModal', 'delete-modal', 'edit', row.item, addFields, meta, null, '', /* addFieldsToGroup, 'attachToGroup', */ deleteUser)" class = "material-icons btn-icon">delete</i><span style="white-space: nowrap">Deletar</span></div>
                    <div><i @click="verUnidadesGerarCND(row.item)" class="material-icons btn-icon">apartment</i><span style="white-space: nowrap">Unidades</span></div>
                </div>
            </template>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="pl-2">Carregando...</strong>
              </div>
            </template>
            <template #head()="key">
              <div class="d-flex flex-row align-items-center">
                <div>{{ key.label }}</div>
                <template v-if="isFiltering && ['name', 'cpf'].includes(key.field.key)">
                  <input
                    v-show="isFiltering"
                    type="text"
                    class="form-control mx-4"
                    :id="'filter-' + key.field.key"
                    @input="filterByApi(false)"
                    v-model="filterData[key.field.key]"
                  />
                </template>
              </div>
            </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          size="md"
          align="right"
          class="my-0"
          @change="filterByApi"
        ></b-pagination>
        <b-modal id="cnd" hide-footer>
        <template #modal-title>Unidades</template>
          <div class="d-flex flex-column justify-content-center align-items-center text-center my-1">
            <b-spinner v-if="loadingCND" variant="primary" label="Spinning"></b-spinner>
            <div class="d-flex flex-column w-100" v-else>
              <template v-if="cnds.length">
                <div class="pb-2 d-flex flex-column align-items-center justify-content-between" v-for="(cnd, i) in cnds" :key="cnd.key">
                  <div class="d-flex flex-row justify-content-between w-100">
                    <div class="d-flex flex-column align-items-start">
                      <span><b>Condomino:</b> {{ cnd.condomino }}</span>
                      <span><b>Bloco:</b> {{ cnd.block }}</span>
                      <span><b>Número:</b> {{ cnd.condominio_numero }}</span>
                      <span><b>Unidade:</b> {{ cnd.key }}</span>
                    </div>
                    <div class="d-flex align-items-center justify-content-center">
                      <div class="d-flex flex-column">
                        <div>
                          <i v-if="!loadingGerarCND.includes(cnd.key)" @click="gerarCND(cnd)" class="material-icons btn-icon">description</i>
                          <b-spinner v-else variant="primary" label="Spinning"></b-spinner>
                        </div>
                        <span>Gerar CND</span>
                      </div>
                    </div>
                  </div>
                  <hr class="w-100" v-if="i + 1 != cnds.length" />
                </div>
              </template>
              <template v-else>
                <div>Sem Unidades</div>
              </template>
            </div>
        </div>
      </b-modal>
    </div>
</template>

<style>

table.b-table[aria-busy='true'] {
  opacity: 1 !important;
}

</style>

<script>

import Header from '@/components/Dashboard/Header'

import api from '@/services/condinvest'
import { toast } from '@/utils'

export default {
  components: {
    Header
  },
  created () {
    api.get('user/index/condomino?group_key=Condômino').then((response) => {
      console.log(response.data)
      if (response.status === 200) {
        this.users = response.data.users
        this.totalRows = response.data.total
        this.busy = false
      }
    }, error => {
      toast(this, 'danger', 'Erro ao obter users', error.message)
      this.busy = false
      return Promise.reject(error)
    })
  },
  data: () => ({
    isFiltering: true,
    users: [],
    busy: true,
    filterData: {},
    totalRows: 1,
    currentPage: 1,
    perPage: 50,
    debounce: false,
    debounceTime: 500, // ms
    loadingCND: false,
    cnds: [],
    loadingGerarCND: [],
    meta: {
      title: 'User',
      module: 'users',
      state: 'users',
      modes: {
        add: {
          title: 'Cadastrar novo Usuário',
          success: 'Usuário cadastrado com successo',
          urn: 'auth/create',
          response: 'user',
          method: 'post'
        },
        edit: {
          title: 'Editar Usuário',
          success: 'Usuário editado com successo',
          urn: 'auth/edit',
          response: 'user',
          method: 'post'
        },
        attachToGroup: {
          title: 'Adicionar Usuário a um Grupo',
          success: 'Usuário linkado com sucesso ao grupo',
          urn: 'group/linkuser',
          response: 'group',
          method: 'post'
        }
      }
    },
    fields: [
      { key: 'cpf', label: 'CPF', sortable: true },
      { key: 'name', label: 'Nome', sortable: true },
      { key: 'groups', label: 'Grupo', formatter: (value, key, item) => value.reduce((str, v) => str + v.key + ', ', '').slice(0, -2) },
      { key: 'actions', label: 'Ações' }
    ],
    addFields: [
      { key: 'name', label: 'Nome', type: 'text' },
      { key: 'email', label: 'Email', type: 'text' },
      { key: 'telephone', label: 'Telefone', type: 'text', mask: '(##) #####-####' },
      { key: 'cpf', label: 'CPF', type: 'text', mask: ['###.###.###-##', '##.###.###/####-##'] }
    ]
    // addFieldsToGroup: [
    // { key: 'user_id', type: 'extra', value: 'id' }, add manually
    // { key: 'group_key', label: 'Grupo', type: 'select', options: [{ value: 'Condômino', text: 'Condômino' }] }
    // ]
  }),
  computed: {
    rules () {
      return this.$store.state.rules.map(a => a.key)
    }
  },
  methods: {

    boletos (item) {
      this.$router.push({ name: 'Boleto', params: { name: item.name, id: item.id } })
    },
    filterByApi (byPaginator) {
      console.log(byPaginator)
      // console.log(this.debounce)
      // if (!this.debounce) {
      this.busy = true

      // debounce to not overload api
      // this.debounce = true

      // const self = this
      // setTimeout(function () {
      //   self.debounce = false
      // }, 500)

      api.get('user/index/condomino?page=' + (byPaginator || 1) + '&group_key=Condômino&name=' + (this.filterData.name || '') + '&cpf=' + (this.filterData.cpf || '')).then((response) => {
        console.log(response.data)
        if (response.status === 200) {
          this.users = response.data.users
          this.totalRows = response.data.total
          if (!byPaginator) this.currentPage = 1
          this.busy = false
        }
      }, error => {
        toast(this, 'danger', 'Erro ao obter users', error.message)
        this.busy = false
        if (!byPaginator) this.currentPage = 1
        this.totalRows = 1
        this.users = []
        return Promise.reject(error)
      })
    },
    deleteUser (user) {
      api.delete('user/delete?user_id=' + user.id).then((response) => {
        console.log(response.data)
        if (response.status >= 200 || response.status < 300) {
          this.$router.go()
        }
      }, error => {
        toast(this, 'danger', 'Erro ao deletar user', error.message)
        return Promise.reject(error)
      })
    },
    verUnidadesGerarCND (user) {
      this.userCND = user
      this.$bvModal.show('cnd')
      this.loadingCND = true
      this.cnds = []
      api.get('unity/index?user_id=' + user.id).then(
        res => {
          this.loadingCND = false
          this.cnds = res.data?.units
        },
        error => {
          toast(this, 'danger', 'Erro ao obter unidades')
          return Promise.reject(error)
        }
      )
    },
    gerarCND (cnd) {
      console.log(cnd)
      // this.loadingGerarCND = true
      this.loadingGerarCND = [...this.loadingGerarCND, cnd.key]
      api.post('declaration/generate', { document: this.userCND.cpf, unidade: cnd.key, condominio: cnd.condominio_numero }).then(
        res => {
          this.loadingGerarCND = this.loadingGerarCND.filter(k => k !== cnd.key)
          if (res.data.url) return window.open(res.data?.url, '_blank').focus()
          toast(this, 'danger', 'Erro ao gerar CND')
        },
        error => {
          this.loadingGerarCND = this.loadingGerarCND.filter(k => k !== cnd.key)
          toast(this, 'danger', 'Erro ao gerar CND')
          return Promise.reject(error)
        }
      )
    }
  }
}
</script>
