<template>
    <div class="header-page">
        <h2>{{ title }}</h2>
        <router-link :to="{name: 'Home'}" tag="span" class="dashboard-link">Dashboard</router-link>
        <span class="slash">/</span>
        <span class="dashboard-title">{{ title }}</span>
        <div class="add-btn" v-if="hasAddBtn" @click="$parent.$emit('callModal', 'form-modal', 'add', null, addFields, meta, extraFields, extraMode)">
            <i class="add-icon-btn material-icons">add</i>
            <span>Adicionar {{ addText }}</span>
        </div>
    </div>
</template>

<style lang="stylus" scoped>

    @import '../../style/colors.styl';
    @import '../../style/fonts.styl';
    @import '../../style/mixins.styl';

    .header-page
        margin-bottom 16px

        > h2
            font-family OpenSans

        > span
            font-family Calibri

    .dashboard-link
        color secondary
        cursor pointer

        &:hover
            color alpha(secondary, 0.8)

    .slash
        color alpha(#888, 0.6)
        font-weight bold
        padding 0 12px
        font-size 0.85em
        vertical-align middle

    .add-btn
        float right
        background-color primary
        color white
        // border-radius 16px
        padding 6px 12px
        cursor pointer
        transition 0.2s
        margin-top -10px

        &:hover
            background-color alpha(primary, 0.8)

        span, .add-icon-btn
            vertical-align middle
            no-select()

    .add-icon-btn
        font-size 1.25em
        padding-right 4px
        margin-left -4px

</style>

<script>

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    addText: {
      type: String,
      default: ''
    },
    addFields: Array,
    meta: Object,
    hasAddBtn: {
      type: Boolean,
      default: true
    },
    extraFields: {
      type: Array,
      required: false
    },
    extraMode: {
      type: String,
      required: false
    }
  }
}
</script>
